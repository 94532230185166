import React, { useContext } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { breakpoints } from 'styles';
import { LocalContext } from 'context';
import QA1 from 'assets/images/docs/qanda1.png';
import QA2 from 'assets/images/docs/qanda2.png';
import QA3 from 'assets/images/docs/qanda3.png';

const QandA = () => {
  const { theme } = useContext(LocalContext);
  return (
    <Wrapper theme={theme}>
      <AnimatePresence>
        <Content
          initial={{
            opacity: 0
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.9
            }
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: 0.6
            }
          }}>
          <h2>Q&A Guidelines</h2>
          <h3>
            As a moderator you have access to the Moderator&apos;s Control Panel from within the Q&A
            feature of the livestream event. Here you can directly reply to incoming questions from
            viewer&apos;s or submit viewer&apos;s questions for discussion during the livestream.
          </h3>
          <FigureWrapper>
            <Figure>
              <ImageWrapper>
                <Image src={QA1} alt="" />
                <ImageCaption>
                  <small>
                    The text field and buttons will be disabled until a question is available and
                    has been selected.
                  </small>
                </ImageCaption>
              </ImageWrapper>
            </Figure>
            <Figure>
              <ImageWrapper>
                <Image src={QA2} alt="" />
                <ImageCaption>
                  As questions come in they will be displayed in chronological order in the live
                  feed.
                </ImageCaption>
              </ImageWrapper>
            </Figure>
            <Figure>
              <ImageWrapper>
                <Image src={QA3} alt="" />
                <ImageCaption>
                  To select or deselect a question simply toggle the corresponding checkbox in the
                  live feed.
                  <br />
                  <br />
                  Choose to either respond directly using the text field provided or have a member
                  of the livestream event bring the answer up in discussion.
                </ImageCaption>
              </ImageWrapper>
            </Figure>
            <h4>Options for responding</h4>
            <Figure>
              <ImageWrapper>
                <iframe
                  title="vimeo-player"
                  src="https://player.vimeo.com/video/663292805?h=9cc467dff2&badge=0&player_id=0&app_id=58479&autoplay=1&loop=1&autopause=0"
                  frameBorder="0"
                  width="100%"
                  height="261"
                  allow="autoplay"
                />
                <ImageCaption>
                  There are two options for responding to viewer&apos;s questions. <br />
                  <br />
                  <br />
                  <strong>Direct response</strong>
                  <br />
                  <br />
                  Respond directly to a viewer&apos;s question by selecting the question, typing the
                  answer in the text field and selecting <strong>SUBMIT ANSWER</strong>. The
                  response will appear underneath the selected question.
                  <br />
                  <br />
                  <br />
                  <strong>Response from member/s of livestream</strong>
                  <br />
                  <br />
                  To submit a viewer&apos;s question for discussion during the livestream event.
                  Select the question and select <strong>ANSWER LIVE</strong>. The question will
                  appear in the livestream for all viewers and participants to see. The question may
                  then be answered by a member or members of the livestream event. To stop showing a
                  viewer&apos;s question select <strong>STOP SHOWING</strong>
                </ImageCaption>
              </ImageWrapper>
            </Figure>
            <h4>Prioritising questions</h4>
            <Figure>
              <ImageWrapper>
                <iframe
                  title="vimeo-player"
                  src="https://player.vimeo.com/video/641126916?autoplay=1&loop=1&autopause=0"
                  frameBorder="0"
                  width="100%"
                  height="261"
                  allow="autoplay"
                />
                <ImageCaption>
                  A prioritised question always appears at the top of the feed. Prioritising can
                  helpful for keeping track of things, allowing you to filter through incoming
                  questions.
                  <br />
                  <br />
                  To prioritise a question select <strong>☆ Prioritise</strong>. Select{' '}
                  <strong>☆ Unprioritise</strong> to remove a question from the top of the feed and
                  place it back in the timeline.
                </ImageCaption>
              </ImageWrapper>
            </Figure>
          </FigureWrapper>
        </Content>
      </AnimatePresence>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.bg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 6.75rem;
  h2 {
    margin: 0.5rem;
    padding: 0.5rem;
  }
  h3 {
    font-weight: normal;
    text-align: center;
    width: 80vw;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 9.75rem;
    h3 {
      width: 50vw;
    }
  }
`;

const Content = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem 0;
`;

const Image = styled.img`
  width: 100%;
`;

const ImageWrapper = styled.div`
  padding: 1rem;
`;

const ImageCaption = styled.figcaption`
  padding: 1rem 0 2rem 0;
  width: 100%;
`;

const Figure = styled.figure`
  background-color: #f9f9f9;
  width: 50vw;
  @media only screen and (max-width: 1024px) {
    width: 80vw;
  }
`;

const FigureWrapper = styled.div`
  margin: 3rem;
  figure {
    margin: 2rem 0;
  }
`;

export default QandA;
